import { isBoolean, isNumber } from 'lodash';
import { action, observable } from 'mobx';
import { scrollToAnchor, getStrLen } from '../../../../utils';

export class AnchorStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public isValid = 'init';

  @observable public allTitles = {
    baseinfo: {
      title: '基本信息',
      requiredNum: 1,
      hasFilled: 0,
      id: 'baseInfo',
    },
    imgTextInfo: {
      title: '图文信息',
      requiredNum: 2,
      hasFilled: 0,
      id: 'imgTextInfo',
    },
    priceStock: {
      title: '价格库存',
      requiredNum: 4,
      hasFilled: 3,
      id: 'priceStock',
    },
    serviceConventions: {
      title: '服务与履约',
      requiredNum: 0,
      hasFilled: 0,
      id: 'serviceConventions',
    },
  };

  @observable public currentLink = '';

  @action public changeAnchor = (currentLink) => {
    this.currentLink = currentLink;
  };

  @action public getIncomplete = async() => {
    const allTitles = this.allTitles;
    this.isValid = 'valid';

    try {
      await this.parent.formRef.current?.validateFields();
    } catch (e) {
      console.log(e);
    } finally {
      const data = await this.parent.formRef.current?.getFieldsValue();

      // 基本信息
      allTitles.baseinfo.hasFilled = 0;
      if (data.goodsName) {
        allTitles.baseinfo.hasFilled++;
      }

      allTitles.baseinfo.requiredNum = 1;

      // 商品属性
      this.parent.goodsProps.forEach((item) => {
        if (item.required) {
          allTitles.baseinfo.requiredNum++;
          if (data[item.id]) {
            allTitles.baseinfo.hasFilled++;
          }
        }
      });

      // 图文信息
      allTitles.imgTextInfo.hasFilled = 0;
      if (this.parent.mainImgsStore.mainPics.length > 0) {
        allTitles.imgTextInfo.hasFilled++;
      }

      if (this.parent.productInfoStore.imgs.length > 0) {
        allTitles.imgTextInfo.hasFilled++;
      }

      // 价格库存
      allTitles.priceStock.hasFilled = 3;
      if (data.salePrice) {
        allTitles.priceStock.hasFilled++;
      }

      // 服务与履约
      allTitles.serviceConventions.hasFilled = 0;

      const { presellType, preSaleTime, deliveryType } = data;
 
      // 预售类型
      allTitles.serviceConventions.requiredNum = 0;

      // 满两件折扣必填
      if (this.parent.twoPiecesDiscountRule.ifMustTwoPiecesDiscount) {
        allTitles.serviceConventions.requiredNum++;
      }

      // 定时预售和时段预售
      if (presellType === 1 || presellType === 2) {
        allTitles.serviceConventions.requiredNum++;
      }

      if (presellType === 1 && preSaleTime) {
        allTitles.serviceConventions.hasFilled++;
      }

      if (presellType === 2 && deliveryType) {
        allTitles.serviceConventions.hasFilled++;
      }

      if (data.twoPiecesDiscount) {
        allTitles.serviceConventions.hasFilled++;
      }
    }
  };
}
