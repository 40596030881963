import { DatePicker, Input, InputNumber } from 'antd';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { SingleUploadImg } from '../../singleUploadImg';
import styles from './index.less';

function disabledDate(current) {
  // 今天之前的时间不可选
  return current && current < moment().endOf('day');
}

// 商品规格表格列
export const saleSpecColumns = (context) => {
  const { colorPropName, sizePropName, selectImg, deleteImg } = context;
  return [
    {
      name: '颜色',
      key: 'color',
      width: 80,
    },
    {
      name: '尺码',
      key: 'size',
      width: 80,
    },
    {
      name: (
        <div className={styles.gridInputWrapper}>
          <span className={styles.requiredTip}>
            *
          </span>
          预览图
        </div>
      ),
      key: 'picUrl',
      width: 80,
      formatter: ({ row }) => {
        return (
          <div className={styles.gridInputWrapper}>
            <SingleUploadImg
              deleteImg={() => {
                console.log(row.goodsSpec);
                console.log(deleteImg);
                deleteImg(row.goodsSpec);
              }}
              fileList={[
                {
                  url: row.picUrl,
                  key: row.goodsSpec,
                },
              ]}
              selectImg={() => {
                selectImg(row.goodsSpec);
              }}
              type="sku"
            />
          </div>
        );
      },
    },
    {
      name: (
        <Observer>
          {
            () => {
              return (
                <div className={styles.gridHeader}>
                  <span className={styles.requiredTip}>
                    *
                  </span>
                  库存
                  <InputNumber
                    className={`${styles.gridHeaderInput} ${styles.gridInput}`}
                    max={999999}
                    min={0}
                    onBlur={() => {
                      context.handleAllNumBlur();
                    }}
                    onChange={(val) => {
                      context.handleAllNumChange(val);
                    }}
                    onPressEnter={() => {
                      context.handleAllNumBlur();
                    }}
                    placeholder="批量填写"
                    precision={0}
                    size="small"
                    value={context.allNum}
                  />
                </div>
              );
            }
          }
        </Observer>
      ),
      width: 140,
      key: 'stockNum',
      formatter: ({ row }) => {
        return (
          <div className={styles.gridInputWrapper}>
            <InputNumber
              max={999999}
              min={0}
              onChange={(price) => {
                const goodsSpec = assembleProperty(colorPropName, row.color, sizePropName, row.size);
                context.handleSingleNumChange(goodsSpec, price);
              }}
              onClick={(e) => e.stopPropagation()}
              precision={0}
              size="small"
              value={row.stockNum}
            />
          </div>
        );
      },
    },
    {
      name: (
        <Observer>
          {
            () => {
              return (
                <div
                  className={`${styles.gridHeaderInput} ${styles.gridInput}`}
                >
                  预售时间
                  <DatePicker
                    disabledDate={disabledDate}
                    onChange={(moment) => {
                      context.handleAllPresaleTimeChange(moment);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    size="small"
                  />
                </div>
              );
            }
          }
        </Observer>
      ),
      width: 200,
      key: 'skuPreSaleTime',
      formatter: ({ row }) => {
        return (
          <div className={styles.gridInputWrapper}>
            <DatePicker
              disabledDate={disabledDate}
              onChange={(moment) => {
                context.handleSingleSkuPresaleTimeChange(row.goodsSpec, moment);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              size="small"
              value={row?.skuPreSaleTime || ''}
            />
          </div>
        );
      },
    },
    {
      name: '进货价',
      key: 'costPrice',
      width: 80,
    },
    {
      name: (
        <Observer>
          {
            () => {
              return (
                <div className={styles.gridInputWrapper}>
                  <span className={styles.requiredTip}>
                    *
                  </span>
                  拼单价
                  <InputNumber
                    className={`${styles.gridHeaderInput} ${styles.gridInput}`}
                    max={999999}
                    min={1}
                    onBlur={() => {
                      context.handleAllMultiPriceBlur();
                    }}
                    onChange={(price) => {
                      context.handleAllMultiPriceChange(price);
                    }}
                    onPressEnter={() => {
                      context.handleAllMultiPriceBlur();
                    }}
                    placeholder="批量填写"
                    precision={2}
                    size="small"
                    value={context.multiPrice}
                  />
                </div>
              );
            }
          }
        </Observer>
      ),
      width: 140,
      key: 'multiPrice',
      formatter: ({ row }) => {
        return (
          <div className={styles.gridInputWrapper}>
            <InputNumber
              max={999999}
              min={1}
              onChange={(price) => {
                const goodsSpec = assembleProperty(colorPropName, row.color, sizePropName, row.size);
                context.handleSingleMultiPriceChange(goodsSpec, price);
              }}
              onClick={(e) => e.stopPropagation()}
              precision={2}
              size="small"
              value={row.multiPrice}
            />
          </div>
        );
      },
    },
    {
      name: (
        <Observer>
          {
            () => {
              return (
                <div className={styles.gridInputWrapper}>
                  <span className={styles.requiredTip}>
                    *
                  </span>
                  单买价
                  <InputNumber
                    className={`${styles.gridHeaderInput} ${styles.gridInput}`}
                    max={999999}
                    min={1}
                    onBlur={() => {
                      context.handleAllSalePriceBlur();
                    }}
                    onChange={(price) => {
                      context.handleAllSalePriceChange(price);
                    }}
                    onPressEnter={() => {
                      context.handleAllSalePriceBlur();
                    }}
                    placeholder="批量填写"
                    precision={2}
                    size="small"
                    value={context.allPrice}
                  />
                </div>
              );
            }
          }
        </Observer>
      ),
      width: 140,
      key: 'salePrice',
      formatter: ({ row }) => {
        return (
          <div className={styles.gridInputWrapper}>
            <InputNumber
              max={999999}
              min={1}
              onChange={(price) => {
                const goodsSpec = assembleProperty(colorPropName, row.color, sizePropName, row.size);
                context.handleSingleSalePriceChange(goodsSpec, price);
              }}
              onClick={(e) => e.stopPropagation()}
              precision={2}
              size="small"
              value={row.salePrice}
            />
          </div>
        );
      },
    },
    {
      name: 'SKU编码',
      key: 'goodsSkuNo',
      width: 200,
      formatter: ({ row }) => {
        return (
          <Input
            defaultValue={row.goodsSkuNo}
            onBlur={(e) => {
              const goodsSpec = assembleProperty(colorPropName, row.color, sizePropName, row.size);
              context.handleSkuChange(goodsSpec, e.target.value);
            }}
            onClick={(e) => e.stopPropagation()}
          />
        );
      },
    },
  ];
};

const assembleProperty = (colorPropName, color, sizePropName, size) => {
  return `${colorPropName}:${color};${sizePropName}:${size}`;
};

