import { Row, Col } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap, MainImgs, ProductInfo, SingleUploadImg } from '../../shelvesBase';
import styles from './index.less';
import type { StepTwoStore } from './store';

export const ImgTextInfo: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { singleUploadImgStore, mainImgsStore, productInfoStore, fullScreenPics, materialPics } = props.store;
  const { deleteImg, selectImg } = singleUploadImgStore;
  return (
    <ContentWrap
      id="imgTextInfo"
      text="图文信息"
    >
      <Row>
        <Col
          className={styles.colContent}
          span={3}
        >
          <span className={styles.requiredTip}>
            *
          </span>
          商品主图
          <span className={styles.colMao}>
            :
          </span>
        </Col>
        <Col span={21}>
          <MainImgs
            singleUploadImgStore={singleUploadImgStore}
            store={mainImgsStore}
          />
        </Col>
        <Col
          className={styles.colContent}
          span={3}
        >
          <span className={styles.requiredTip}>
            *
          </span>
          商品详情
          <span className={styles.colMao}>
            :
          </span>
        </Col>
        <Col span={21}>
          <ProductInfo
            productImgTip="(图片宽度应为480-1200px，高度应为1500px以内)"
            singleUploadImgStore={singleUploadImgStore}
            store={productInfoStore}
          />
        </Col>
        <Col
          className={styles.colContent}
          span={3}
        >
          商品素材:
        </Col>
        <Col
          className={styles.productMaterial}
          span={21}
        >
          商品发布成功且审核通过后，系统会自动上传素材图片，若商品审核被拒，素材会上传失败。白底图尺寸建议480*480，
          长图尺寸建议400*600，大小不超过3M，支持JPG/JPEG/PNG
          <div className={styles.uploadWrapper}>
            <div className={styles.eachUpload}>
              <SingleUploadImg
                deleteImg={deleteImg}
                fileList={materialPics}
                imgSize={{
                  width: 480,
                  height: 480,
                }}
                selectImg={selectImg}
                type="whiteBgImg"
              />
              <div className={styles.eachUploadText}>
                白底图
              </div>
            </div>
            <div className={styles.eachUpload}>
              <SingleUploadImg
                deleteImg={deleteImg}
                fileList={fullScreenPics}
                imgSize={{
                  width: 400,
                  height: 600,
                }}
                selectImg={selectImg}
                type="longImg"
              />
              <div className={styles.eachUploadText}>
                长图
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </ContentWrap>
  );
});

