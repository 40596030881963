import { InputNumber, Button, Divider, message } from 'antd';
import React, { useState } from 'react';
import styles from './index.less';

interface ProfitProps{
  handleProfitAdd: (profit: number, multiAdd: number) => void;
}

export const ProfitInput: React.FC<ProfitProps> = (props) => {
  const [
    profit,
    setProfit,
  ] = useState();

  const [
    multiAdd,
    setMultiAdd,
  ] = useState();

  const handleProfitChange = (val) => {
    setProfit(val);
  };

  const handleMultiAdd = (val) => {
    setMultiAdd(val);
  };
  const handleConfirm = () => {
    if (!profit || !multiAdd) {
      message.error('检查输入是否完整');
      return;
    }

    props.handleProfitAdd(profit, multiAdd);
    setProfit(null);
    setMultiAdd(null);
  };
  return (
    <div className={styles.profitWrapper}>
      <span>
        拼单价=进货价+
      </span>
      <InputNumber
        min={0}
        onChange={handleProfitChange}
        placeholder="请输入利润(元)"
        precision={2}
        step={1}
        value={profit}
      />
      <span className={styles.m12}>
        单买价=拼单价+
      </span>
      <InputNumber
        min={0}
        onChange={handleMultiAdd}
        placeholder="请输入金额(元)"
        precision={2}
        step={1}
        value={multiAdd}
      />
      <Button
        className={styles.buttonWrapper}
        onClick={handleConfirm}
      >
        确定
      </Button>
      <Divider
        className={styles.divider}
        type="vertical"
      />
    </div>
  );
};
