import { Form, Input, Col, Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap, calcStrLen } from '../../shelvesBase';
import { FORM_LAY_OUT } from '../constant';
import styles from './index.less';

const { Option } = Select;
const { Item } = Form;

export const BaseInfo = observer((props: { store; }) => {
  const { productHasFilled, handleProductChange, productName, goodsProps, formItemOnChange } = props.store;
  return (
    <ContentWrap
      id="baseInfo"
      text="基本信息"
    >
      <Item
        label="商品标题"
        name="goodsName"
        rules={
          [
            {
              required: true,
              message: '请输入商品标题',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if ((!value || (value && calcStrLen(value) < 61))) {
                  return Promise.resolve();
                }
                return Promise.reject('商品标题最多输入60个字符');
              },
            }),
          ]
        }
      >
        <Input
          onChange={handleProductChange}
          placeholder="商品标题组成：商品描述+规格 最多输入30个汉字"
          suffix={`${productHasFilled}/60`}
        />
      </Item>
      {
        goodsProps && goodsProps.length > 0 && (
          <Item label="商品属性">
            <div className={styles.itemWrap}>
              {goodsProps && goodsProps.map((item, index) => {
                // ... 在此处不同布局的判断
                const key = item.id;
                return (
                  <Col
                    className={item.className || ''}
                    key={key}
                    span={item.colspan || 12}
                  >
                    <Item
                      {...(item.itemLayout || FORM_LAY_OUT)}
                      initialValue={item.type === 'INPUT' ? item.value : (item.type === 'MULTICHECK' ? (item.value ? item.value.split(',') : undefined) : (item.value || undefined))}
                      label={item.name}
                      name={key}
                      normalize={(value, prevValue, allValues) => {
                        if (item.type === 'MULTICHECK') {
                          if (typeof value === 'string') {
                            return value.split(',');
                          }
                        }
                        return value;
                      }}
                      rules={[
                        item.rules || {
                          required: item.required,
                          message: item.message,
                        },
                      ]}
                    >
                      {item.type === 'INPUT' ? (
                        <Input
                          autoComplete="off"
                          className={item.className || ''}
                          id={key}

                          // onChange={(e) => {
                          //   formItemOnChange(e, {
                          //     type: 'input',
                          //     key,
                          //     index,
                          //   });
                          // }}
                          placeholder={item.placeholder || '请输入'}
                        />
                      ) : (
                        <Select
                          allowClear={item.type !== 'INPUT'}
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          mode={item.type === 'MULTICHECK' ? 'multiple' : null}
                          notFoundContent={item.fetching ? <Spin size="small"/> : '无数据'}
                          onChange={(val) => {
                            formItemOnChange(key, val, index);
                          }}
                          placeholder="请选择"
                          showSearch
                        >
                          {item.checkOptions && item.checkOptions.map((v) => {
                            return (
                              <Option
                                key={v.key}
                                value={v.key}
                              >
                                {v.value}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Item>
                  </Col>
                );
              })}
            </div>
          </Item>
        )
      }
    </ContentWrap>
  );
});
