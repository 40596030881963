import { Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import ImgOrVideoUpload from '../../../utils/upLoad';
import { formLayout, PublishResult, FootPart, AnchorCustom } from '../../shelvesBase';
import { BaseInfo } from './baseInfo';
import { ImgTextInfo } from './imgTextInfo';
import { PriceStock } from './priceStock';
import { ProfitInput } from './profitInput';
import { ServiceConventions } from './serviceConventions';
import type { StepTwoStore } from './store';

export const StepTwo: React.FC<{ stepTwoStore: StepTwoStore; }> = observer((props) => {
  const { stepTwoStore } = props;
  const { sizeColorStore, formRef, anchorStore, singleUploadImgStore, saveInfo, submitInfo, resultVisilbe, closeResultModal, closeTab, submitResult, listingMode, shopName, errorMessage, goodsId } = stepTwoStore;
  const { platformType } = stepTwoStore.parent;
  return (
    <Form
      ref={formRef}
      {...formLayout}
    >
      <BaseInfo store={stepTwoStore}/>
      <ImgTextInfo store={stepTwoStore}/>
      <PriceStock store={stepTwoStore}/>
      <ServiceConventions store={stepTwoStore}/>
      <ImgOrVideoUpload store={singleUploadImgStore.uploadStoreImg}/>
      <AnchorCustom store={anchorStore}/>
      <FootPart
        save={saveInfo}
        submitInfo={submitInfo}
      >
        <ProfitInput handleProfitAdd={sizeColorStore.handleSalePriceAddProfit}/>
      </FootPart>
      <PublishResult
        closeModal={closeTab}
        closeResultModal={closeResultModal}
        errorMessage={errorMessage}
        goodsId={goodsId}
        listingTimeMode={listingMode}
        platformId={platformType}
        shopName={shopName}
        submitResult={submitResult}
        visibleSubmitResult={resultVisilbe}
      />
    </Form>
  );
});

