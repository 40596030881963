export const STEPS = [
  {
    key: '1',
    title: '选择类目',
  },
  {
    key: '2',
    title: '完善商品信息',
  },
];

export const FORM_LAY_OUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export const SHIP_LIMIT_DICT = {
  '0': {
    key: 0,
    label: '当日发',
  },
  '86400': {
    key: 1,
    label: '24小时发',
  },
  '172800': {
    key: 2,
    label: '48小时发',
  },
  '432000': {
    key: 3,
    label: '5天发',
  },
};

