import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import { CategorySelectorStore } from '../shelvesBase';
import type { IGoodsCatRule } from './interface';
import { StepTwoStore } from './stepTwo/store';

export class ShelvesPddStore {
  constructor() {
    const params = new URLSearchParams(new URL(window.location.href).search);
    this.shopId = params.get('shopId');
    this.gmsGoodsId = params.get('gmsGoodsId');
    this.platformType = params.get('platformType');
    this.pageId = params.get('pageId');
    this.initData();

    // 获取运费模板
    this.getlogisticTem();

    // 获取货源地信息
    this.getCoutryList(this.shopId);

    // 获取主图款式库照片
    this.stepTwoStore.getPosGoodsGroupPics();
  }

  @observable public categoryStore = new CategorySelectorStore();

  @observable public pageId = '';

  @observable public shopId = '';

  @observable public gmsGoodsId = '';

  @observable public platformType = '';

  @observable public logisticsTemplateOptions = [];// 运费模版

  @observable public stepTwoStore = new StepTwoStore(this);

  @observable public loading = false;

  @observable public currentStep = 0;

  @observable public categoryId: string | number;// 选中类目id

  @observable public categoryName = '';// 选中类目名称

  @observable public categoryIds = '';

  @action public onNext = async() => {
    const categoryIds = this.categoryStore.selectedValue;
    const categoryId = categoryIds[categoryIds.length - 1];
    this.categoryId = categoryId;
    this.categoryIds = categoryIds.join(',');
    this.categoryName = this.categoryStore.selectTxt;
    this.loading = true;
    try {
      await this.checkCategoryChanged();
      this.currentStep++;

      // 获取产品发布规则
      await this.getGoodsCatRule(categoryId);
      const DetailRes = await request<BaseData>({
        url: '/api/gms/goods/platform/pdd/getGoodsPutOnShelfDetail',
        method: 'POST',
        data: {
          categoryId,
          gmsGoodsId: this.gmsGoodsId,
        },
      });
      this.stepTwoStore.initFormData(DetailRes.data);
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  };

  @action public initData = async() => {
    this.loading = true;
    try {
      await this.categoryStore.initData(this.gmsGoodsId, this.platformType, this.shopId);
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  };

  // 检查是否修改类目
  public checkCategoryChanged = () => {
    return request<BaseData>({
      url: '/api/gms/goods/platform/ks/checkCategoryChanged',
      method: 'POST',
      data: {
        categoryId: this.categoryId,
        gmsGoodsId: this.gmsGoodsId,
      },
    });
  };

  @action public mapOptions = (data, key, val) => {
    return data?.length > 0 ? data.map((item) => ({
      label: item[val],
      value: item[key],
    })) : [];
  };

  @action public getlogisticTem = async() => {
    // 获取商品运费模板
    const logisticTemRes = await request<BaseData>({
      url: '/api/gms/goods/platform/pdd/queryLogisticsTemplate',
      method: 'POST',
      data: { shopId: this.shopId },
    });
    
    this.stepTwoStore.logisticsTemplateOptions = this.mapOptions(logisticTemRes.data, 'templateId', 'templateName');
  };

  // 获取类目的发货规则
  @action public getGoodsCatRule = async(categoryId: number | string) => {
    const catRuleRes = await request<BaseData<IGoodsCatRule>>({
      url: '/api/gms/goods/platform/pdd/getGoodsCatRule',
      method: 'POST',
      data: {
        categoryId,
        shopId: this.shopId,
      },
    });
    const { twoPiecesDiscountRule, goodsSkuRule } = catRuleRes.data;
    const { goodsTypeNameMap, goodsServiceRuleMap } = catRuleRes.data.goodsServiceRule;
    this.stepTwoStore.productTypeOptions = Object.keys(goodsTypeNameMap).map((key) => ({
      label: goodsTypeNameMap[key],
      value: Number(key),
    }))
      .slice(0, 1);// 目前只显示普通商品
    this.stepTwoStore.sizeColorStore.priceRangeRatio = goodsSkuRule.priceRangeRatio;
    this.stepTwoStore.goodsServiceMap = goodsServiceRuleMap;
    this.stepTwoStore.twoPiecesDiscountRule = twoPiecesDiscountRule;
  };

  // 获取国家或地区信息
  @action public getCoutryList = async(shopId) => {
    const countryRes = await request<BaseData>({
      url: '/api/gms/goods/platform/pdd/listCountry',
      method: 'POST',
      data: { shopId },
    });

    this.stepTwoStore.countryOpitons = this.mapOptions(countryRes.data, 'countryId', 'countryName');
  };
}

