import { PlusOutlined, CloseCircleFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import React from 'react';
import deleteIcon from '../../../../assets/images/deleteIcon.png';
import styles from './index.less';

interface IUpload{
  type: string;
  deleteImg: (type, key) => void;
  selectImg: (type, key, size?, limitSize?) => void;
  fileList: IEachFile[];
  imgSize?: IImgSize;// 图片限制大小
  limitSize?: number;// 图片文件限制大小
}

interface IEachFile{
  key: string;
  url: string;
}

interface IImgSize{
  width?: number;
  height?: number;
  ratio?: number;
  minWidth?: number;
  minHeight?: number;
}

const skuStyle = {
  width: '32px',
  height: '32px',
  padding: 0,
};

const skuDelete = {
  top: '-4px',
  right: '-4px',
  transform: 'scale(0.5)',
};
@observer
class SingleUploadImg extends React.Component<IUpload> {
  render(): JSX.Element {
    const { selectImg, fileList, deleteImg, type, imgSize, limitSize } = this.props;
    return (
      <div>
        {
          fileList && fileList.length > 0 && fileList[0].url ? (
            <div
              className={styles.imgWrap}
              style={type === 'sku' ? skuStyle : {}}
            >
              <div
                className={styles.imgCom}
                style={type === 'sku' ? skuStyle : {}}
              >
                <img
                  alt=""
                  src={fileList[0].url}
                />
              </div>
              <img
                className={styles.delete}
                onClick={() => {
                  deleteImg(type, fileList[0]?.key);
                }}
                src={deleteIcon}
                style={type === 'sku' ? skuDelete : {}}
              />
              {/* <CloseCircleFilled
                className={styles.delete}
                onClick={() => {
                  deleteImg(type, fileList[0]?.key);
                }}
                style={type === 'sku' ? skuDelete : {}}
              /> */}
            </div>
          ) : (
            <div
              className={styles.singleUploadImgWrap}
              onClick={() => {
                selectImg(type, fileList[0]?.key, imgSize, limitSize);
              }}
              style={type === 'sku' ? skuStyle : {}}
            >
              <div className={styles.plusIcon}>
                <PlusOutlined style={type !== 'sku' ? { fontSize: 20 } : { fontSize: 12 }}/>
              </div>
              {
                type !== 'sku' && (
                  <div>
                    上传图片
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
}

export { SingleUploadImg };

