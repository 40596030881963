import { Form, Input, Radio, Select, Checkbox, Row, Col, InputNumber, DatePicker } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { ContentWrap } from '../../shelvesBase';
import { SHIP_LIMIT_DICT } from '../constant';
import styles from './index.less';
import type { StepTwoStore } from './store';

const { Item } = Form;

export const ServiceConventions: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { productTypeOptions, countryOpitons, twoPiecesDiscountRule, logisticsTemplateOptions, handlePresellChange, handleProductTypeChange } = props.store;
  return (
    <ContentWrap
      id="serviceConventions"
      style={{ borderBottom: 'none' }}
      text="服务与履约"
    >
      <Item
        label="商品类型"
        name="goodsType"
        rules={[{ required: true }]}
      >
        <Radio.Group
          onChange={(e) => {
            handleProductTypeChange(e.target.value);
          }}
        >
          {
            productTypeOptions.map((item) => (
              <Radio
                key={item.value}
                value={item.value}
              >
                {item.label}
              </Radio>
            ))
          }
        </Radio.Group>
      </Item>
      <Item
        noStyle
        shouldUpdate={(preValues, curValues) => (preValues.goodsType !== curValues.goodsType)}
      >
        {
          ({ getFieldValue }) => {
            return getFieldValue('goodsType') === 1 ? (
              <SecondHand/>
            ) : <CountryPlace options={countryOpitons}/>;
          }
        }
      </Item>
      
      <Item
        label="是否预售"
        name="presellType"
        rules={[{ required: true }]}
      >
        <Radio.Group onChange={(e) => {
          handlePresellChange(e.target.value);
        }}
        >
          <Radio value={0}>
            非预售
          </Radio>
          <Radio value={1}>
            定时预售
          </Radio>
          <Radio value={2}>
            时段预售
          </Radio>
          <Radio value={3}>
            SKU预售
          </Radio>
        </Radio.Group>
      </Item>
      <Item
        noStyle
        shouldUpdate={(preValues, curValues) => (preValues.presellType !== curValues.presellType)}
      >
        {
          ({ getFieldValue }) => {
            return getFieldValue('presellType') !== 2 ? (<ShipmentLimitSecond store={props.store}/>) : <ShipmentLimitSecondGroup/>;
          }
        }
      </Item>
      <Item
        noStyle
        shouldUpdate={(preValues, curValues) => (preValues.presellType !== curValues.presellType)}
      >
        {
          ({ getFieldValue }) => {
            return getFieldValue('presellType') === 1 ? (<PresellTime/>) : null;
          }
        }
      </Item>
      {
        twoPiecesDiscountRule.ifMustTwoPiecesDiscount ? <TwoPiecesDiscount store={props.store}/> : null
      }
      <Item
        label="运费模版"
        name="costTemplateId"
        rules={[{ required: true }]}
      >
        <Select
          options={logisticsTemplateOptions}
          style={{ width: '180px' }}
        />
      </Item>
      <Item
        label="拼单要求"
        rules={[{ required: true }]}
      >
        <Input
          className={styles.inputStyle}
          disabled
          suffix="人"
          value="2"
        />
      </Item>
      <Item
        label="承诺"
        name="refundable"
        rules={[{ required: true }]}
      >
        <Checkbox
          checked
          disabled
        >
          7天无理由退换货
        </Checkbox>
      </Item>
      <Item
        colon={false}
        label=" "
      >
        <Item
          name="folt"
          noStyle
          valuePropName="checked"
        >
          <Checkbox>
            假一罚十
          </Checkbox>
        </Item>
      </Item>
      <Item
        label="上架时间"
        name="operateType"
      >
        <Radio.Group>
          <Radio value={0}>
            立即上架
          </Radio>
          <Radio value={1}>
            放入草稿箱
          </Radio>
        </Radio.Group>
      </Item>
    </ContentWrap>
  );
});

function disabledDate(current) {
  // 今天之后第三天之前的时间都不可
  return current && current < moment().add(3, 'days');
}

// 预售时间
const PresellTime = () => {
  return (
    <Item
      label="预售时间"
      name="preSaleTime"
      rules={[{ required: true }]}
    >
      <DatePicker
        className={styles.inputStyle}
        disabledDate={disabledDate}
      />
    </Item>
  );
};

// 承诺发货时间
const ShipmentLimitSecond: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { shipLimitOptions } = props.store;
  return (
    <Item
      label="承诺发货时间"
      labelCol={{ span: 3 }}
      name="deliveryType"
      rules={[{ required: true }]}
    >
      <Radio.Group>
        {
          shipLimitOptions.map((item) => (
            <Radio
              key={item}
              value={item}
            >
              {SHIP_LIMIT_DICT[item].label}
            </Radio>
          ))
        }
      </Radio.Group>
    </Item>
  );
});

// 时段预售对应的发货时间
const ShipmentLimitSecondGroup: React.FC = observer(() => {
  return (
    <Item
      label="承诺发货时间"
      required
    >
      支付成功后
      <Item
        name="deliveryType"
        noStyle
        rules={[
          {
            required: true,
            message: '请输入承诺发货时间',
          },
        ]}

      >
        <InputNumber
          className={styles.inputNumberWrapper}
          max={15}
          min={3}
          precision={0}
          step={1}
        />
      </Item>
      天内发货
    </Item>
  );
});

// 满两件折扣
const TwoPiecesDiscount: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { twoPiecesDiscountRule } = props.store;
  return (
    <Item
      initialValue={twoPiecesDiscountRule.recommendTwoPiecesDiscount}
      label="满两件折扣"
      labelCol={{ span: 3 }}
      name="twoPiecesDiscount"
      rules={[{ required: true }]}
    >
      <InputNumber
        max={twoPiecesDiscountRule.maxTwoPiecesDiscount}
        min={twoPiecesDiscountRule.minTwoPiecesDiscount}
      />
    </Item>
  );
});

// 二手
const SecondHand = () => {
  return (
    <Item
      label="是否二手"
      name="secondHand"
      rules={[{ required: true }]}
    >
      <Radio.Group>
        <Radio value={false}>
          非二手
        </Radio>
        <Radio value>
          二手
        </Radio>
      </Radio.Group>
    </Item>
  );
};

// 货源地
const CountryPlace: React.FC<{ options: any[]; }> = (props) => {
  const { options } = props;
  return (
    <Item
      label="货源地"
      name="countryId"
      rules={[{ required: true }]}
    >
      <Select
        options={options}
        style={{ width: '180px' }}
      />
    </Item>
  );
};
