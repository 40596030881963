import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import { UploadStore } from '../../../../utils/upLoad/uploadStore';

export class SingleUploadImgStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public type = '';

  @observable public code = '';

  @observable public mainPicTip = '建议上传图片比例1:1或3:4，且最小宽高为480px，大小1M以内';

  @action public callBack = (params) => {
    const { mainPics } = this.parent.mainImgsStore;

    const { imgs } = this.parent.productInfoStore;
    const { colorList } = this.parent.sizeColorStore;
    if (this.type === 'color') {
      const colorIndex = this.parent.sizeColorStore.colorList.findIndex((item) => item.name === this.code);
      this.parent.sizeColorStore.colorList[colorIndex].url = params[0].url;

      // 给预览图增加图片
      const colorName = this.parent.sizeColorStore.colorList[colorIndex].name;
      this.parent.sizeColorStore.goodsSkuList.forEach((item) => {
        const eachColorName = item.goodsSpec.split(';')[0].split(':')[1];
        if (eachColorName === colorName) {
          item.picUrl = params[0].url;
        }
      });
      this.parent.sizeColorStore.productSaleSpecGridModel.rows = this.parent.sizeColorStore.mapSpecRows(this.parent.sizeColorStore.goodsSkuList);
    } else if (this.type === 'mainPic') {
      const uploadImgs = this.handleImgs(params);
      this.parent.mainImgsStore.mainPics = [
        ...mainPics,
        ...(uploadImgs),
      ];
    } else if (this.type === 'productInfoImg') {
      const uploadImgs = this.handleImgs(params);
      this.parent.productInfoStore.imgs = [
        ...imgs,
        ...uploadImgs,
      ];
    } else if (this.type === 'whiteBgImg') {
      this.parent.materialPics = params;
    } else if (this.type === 'longImg') {
      this.parent.fullScreenPics = params;
    } else if (this.type === 'sku') {
      const skuIndex = this.parent.sizeColorStore.goodsSkuList.findIndex((item) => item.goodsSpec === this.code);
      this.parent.sizeColorStore.goodsSkuList[skuIndex].picUrl = params[0].url;
      this.parent.sizeColorStore.productSaleSpecGridModel.rows = this.parent.sizeColorStore.mapSpecRows(this.parent.sizeColorStore.goodsSkuList);
    }
    this.type = '';
  };

  @action public handleImgs = (imgs) => {
    return imgs.map((item) => {
      item.imgId = nanoid();
      return item;
    });
  };

  @observable public uploadStoreImg = new UploadStore({
    type: 'picManage', // picManage 图片 videoManage 视频
    multiple: false, // 是否允许多选文件
    limitNumber: 1, // 限制只能上传一个文件或者说只能选择一个文件
    callBack: this.callBack,
  });

  @action public selectImg = (type, code?, imgSize?): void => {
    let limitSize;
    if (type === 'mainPic') {
      imgSize = {
        ratio: [
          1,
          0.75,
        ],
        minWidth: 480,
      };
      limitSize = 1;
    }

    if (type === 'color') {
      imgSize = {
        ratio: 1,
        minWidth: 480,
      };
    }

    if (type === 'productInfoImg') {
      imgSize = {
        minWidth: 480,
        maxWidth: 1200,
        maxHeight: 1500,
      };
    }
    const multiple = Boolean(type === 'mainPic') || Boolean(type === 'productInfoImg');// 主图和详情允许多选
    this.type = type;
    this.code = code;
    this.uploadStoreImg = new UploadStore({
      type: 'picManage', // picManage 图片 videoManage 视频
      // activeTab: 'manage',
      imgSize,
      limitSize,
      multiple, // 是否允许多选文件
      limitNumber: (multiple) ? 999 : 1, // 限制只能上传一个文件或者说只能选择一个文件
      callBack: this.callBack,
    });

    this.uploadStoreImg.onOpen();
  };

  // 删除颜色图片
  @action public deleteImg = (type, key) => {
    if (type === 'color') {
      // 颜色
      const deleteIndex = this.parent.sizeColorStore.colorList.findIndex((item) => item.name === key);
      this.parent.sizeColorStore.colorList[deleteIndex].url = '';
    }

    // 白底图
    if (type === 'whiteBgImg') {
      this.parent.materialPics = [];
    }

    // 长图图
    if (type === 'longImg') {
      this.parent.fullScreenPics = [];
    }

    // sku
    if (type === 'sku') {
      console.log(key);
      console.log(this.parent.sizeColorStore);
      const deleteIndex = this.parent.sizeColorStore.goodsSkuList.findIndex((item) => item.goodsSpec === key);
      this.parent.sizeColorStore.goodsSkuList[deleteIndex].picUrl = '';
      this.parent.sizeColorStore.productSaleSpecGridModel.rows = this.parent.sizeColorStore.mapSpecRows(this.parent.sizeColorStore.goodsSkuList);
    }
  };
}
